<template>
  <v-card style="border-radius: 20px !important">
    <v-card-actions class="py-1">
      <div style="height: 24px; margin: 0 auto">
        <v-btn
          @click="$emit('calendar-clicked')"
          icon
          v-if="calendarActive"
          width="24"
          height="24"
        >
          <img
            class="color-filter"
            :src="
              calendarActive
                ? require('@/assets/reservation/calendar_active.svg')
                : require('@/assets/reservation/calendar.svg')
            "
          />
        </v-btn>
        <v-btn
          v-else
          @click="$emit('calendar-clicked')"
          plain
          text
          class="Heading-Dark-H5-16-Center"
          style="height: 24px"
        >
          {{ selectedDate.croppedDate }}
        </v-btn>
      </div>
      <v-divider vertical></v-divider>
      <div style="height: 24px; margin: 0 auto">
        <v-btn
          @click="$emit('people-clicked')"
          icon
          v-if="!peopleActive"
          width="24"
          height="24"
        >
          <img
            class="color-filter"
            width="24"
            height="24"
            :src="
              timeActive
                ? require('@/assets/reservation/time_active.svg')
                : require('@/assets/reservation/time.svg')
            "
            style="margin: 0 auto"
          />
        </v-btn>
        <v-btn
          v-else
          @click="$emit('people-clicked')"
          plain
          text
          class="Heading-Dark-H5-16-Center"
          style="height: 24px"
        >
          {{ selectedTime.time }}
        </v-btn>
      </div>
      <v-divider vertical></v-divider>
      <div style="height: 24px; margin: 0 auto">
        <v-btn
          @click="$emit('guests-clicked')"
          icon
          width="24"
          height="24"
          color="black"
          v-if="!selectedGuests || !peopleActive"
          style="margin: 0 auto"
        >
          <img
            class="color-filter"
            :src="
              peopleActive
                ? require('@/assets/reservation/people_active.svg')
                : require('@/assets/reservation/people.svg')
            "
          />
        </v-btn>
        <v-btn
          v-else
          @click="$emit('guests-clicked')"
          plain
          text
          class="Heading-Dark-H5-16-Center"
          style="height: 24px"
        >
          {{ selectedGuests.number }}
          {{
            selectedGuests.number === 1
              ? $t("reservation.guest")
              : $t("reservation.guests")
          }}
        </v-btn>
      </div>
    </v-card-actions>
    <v-card-actions class="px-10 pt-0"
      ><v-progress-linear
        background-color="onbackground"
        color="primary"
        rounded
        :value="progress"
      ></v-progress-linear
    ></v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: [
    "progress",
    "selectedGuests",
    "selectedDate",
    "selectedTime",
    "calendarActive",
    "peopleActive",
    "timeActive",
  ],
};
</script>
