<template>
  <v-container>
    <v-img
      :src="require('@/assets/reservation_bg.png')"
      contain
      style="position: absolute; top: 0; right: 0"
    ></v-img>
    <back-toolbar :title="$t('reservation.modifyBooking')"></back-toolbar>
    <reservation-progress-bar
      :progress="progress"
      :selectedGuests="selectedGuests"
      :selectedDate="selectedDate"
      :selectedTime="selectedTime"
      :calendarActive="calendarActive"
      :peopleActive="peopleActive"
      :timeActive="timeActive"
      @calendar-clicked="
        (progress = 30),
          (calendarActive = true),
          (peopleActive = false),
          (timeActive = false)
      "
      @people-clicked="
        (progress = 70),
          (calendarActive = false),
          (timeActive = true),
          (peopleActive = false)
      "
      @guests-clicked="
        (progress = 100),
          (peopleActive = true),
          (calendarActive = false),
          (timeActive = false)
      "
      class="mt-12"
    ></reservation-progress-bar>
    <v-row v-if="calendarActive && mounted">
      <v-col cols="12">
        <v-card class="mt-12" elevation="0">
          <date-picker
            v-model="date"
            mode="date"
            is-expanded
            show-current
            style="border: none"
            color="blue"
            :min-date="
              !availableDates || !availableDates.length ? localDate : undefined
            "
            :available-dates="availableDates"
            :disabled-dates="disabledDates"
          ></date-picker>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="timeActive">
      <v-col>
        <v-card
          elevation="0"
          style="
            height: calc(100vh - 20rem);
            overflow-y: scroll;
            padding-bottom: 4rem;
          "
        >
          <div
            v-if="breakfast.length"
            class="Heading-Dark-H4-18-Left-Align ml-1 mt-3"
          >
            {{ $t("reservation.breakfast") }}
          </div>
          <v-divider v-if="breakfast.length"></v-divider>
          <v-btn
            v-for="time in breakfast"
            :key="time.time"
            :ripple="false"
            style="border-radius: 12px; width: 22%"
            outlined
            class="px-5 mx-1 mt-2 Heading-Dark-H6-14-Center"
            :class="time.selected ? 'gradient white--text' : ''"
            @click="select(time)"
            >{{ time.time }}</v-btn
          >
          <div
            v-if="lunch.length"
            class="Heading-Dark-H4-18-Left-Align ml-1 mt-3"
          >
            {{ $t("reservation.lunch") }}
          </div>
          <v-divider v-if="lunch.length"></v-divider>
          <v-btn
            v-for="time in lunch"
            :key="time.time"
            :ripple="false"
            style="border-radius: 12px; width: 22%"
            outlined
            class="px-5 mx-1 mt-2 Heading-Dark-H6-14-Center"
            :class="time.selected ? 'gradient white--text' : ''"
            @click="select(time)"
            >{{ time.time }}</v-btn
          >
          <div
            v-if="dinner.length"
            class="Heading-Dark-H4-18-Left-Align ml-1 mt-3"
          >
            {{ $t("reservation.dinner") }}
          </div>
          <v-divider v-if="dinner.length"></v-divider>
          <v-btn
            v-for="time in dinner"
            :key="time.time"
            :ripple="false"
            style="border-radius: 12px; width: 22%"
            outlined
            class="px-5 mx-1 mt-2 Heading-Dark-H6-14-Center"
            :class="time.selected ? 'gradient white--text' : ''"
            @click="select(time)"
            >{{ time.time }}</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="peopleActive">
      <v-col>
        <v-card elevation="0">
          <div class="Heading-Dark-H4-18-Left-Align ml-1 mt-3">
            {{ $t("reservation.guestsNumber") }}
          </div>
          <v-divider></v-divider>
          <v-btn
            v-for="guest in guests"
            :key="guest.number"
            :ripple="false"
            style="border-radius: 12px; width: 22%"
            outlined
            :class="guest.selected ? 'gradient white--text' : ''"
            @click="select(guest)"
            class="px-5 mx-1 mt-2 Heading-Dark-H6-14-Center"
            >{{ guest.number }}</v-btn
          >
        </v-card>
      </v-col>
    </v-row>
    <div
      :style="
        windowHeight > 650
          ? 'position: absolute; bottom: 60px; width: 100%; left: 0'
          : ''
      "
    >
      <v-card
        :style="$store.state.settings.dark ? 'background-color: #121212' : ''"
        elevation="0"
      >
        <v-card-actions>
          <v-btn
            width="90%"
            height="48"
            depressed
            color="primary"
            :loading="$store.state.reserveout.status === 'fetching'"
            class="gradient mt-5 Heading-White-H4-18-Center d-block mx-auto"
            @click="modifyReservation"
            >{{ $t("reservation.modify") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
    <v-dialog
      v-model="errorDialog"
      max-width="325"
      :timeout="-1"
      overlay-color="backgroundoverlay"
      overlay-opacity="1"
    >
      <v-card
        style="border-radius: 20px !important"
        flat
        class="pa-0"
        v-if="$store.state.reserveout.error"
      >
        <v-toolbar flat dense>
          <v-spacer></v-spacer>
          <v-btn icon @click="errorDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="Text-Dark-Text-1---18-Center mt-4">{{
          $store.state.reserveout.error
        }}</v-card-text>
        <v-container class="text-center">
          <v-row>
            <v-col cols="12">
              <v-btn
                @click="errorDialog = false"
                min-height="48"
                min-width="150"
                class="gradient white--text Text-Dark-Text-1---18-Center"
                >{{ $t("profile.ok") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import ReservationProgressBar from "@/components/app/ReservationProgressBar";
import BackToolbar from "@/components/app/common/BackToolbar";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import find from "lodash/find";
import api from "@/api";

export default {
  data: () => ({
    progress: 30,
    date: null,
    hours: [],
    halfHours: ["00", "30"],
    guests: [
      {
        number: 1,
        selected: false,
      },
      {
        number: 2,
        selected: false,
      },
      {
        number: 3,
        selected: false,
      },
      {
        number: 4,
        selected: false,
      },
      {
        number: 5,
        selected: false,
      },
      {
        number: 6,
        selected: false,
      },
      {
        number: 7,
        selected: false,
      },
      {
        number: 8,
        selected: false,
      },
      {
        number: 9,
        selected: false,
      },
      {
        number: 10,
        selected: false,
      },
    ],
    daylist: [],
    timeModel: null,
    timeIndex: null,
    dateModel: null,
    dateIndex: null,
    guestModel: null,
    guestIndex: null,
    calendarActive: true,
    peopleActive: false,
    timeActive: false,
    mounted: false,
    promotion: -1,
  }),
  components: {
    ReservationProgressBar,
    BackToolbar,
    DatePicker,
  },
  computed: {
    windowHeight() {
      return window.innerHeight;
    },
    errorDialog: {
      get() {
        return this.$store.state.reserveout.status === "failure";
      },
      set(val) {
        this.$store.commit("reserveout/RESET_ERROR");
      },
    },
    selectedGuests() {
      const obj = this.guests.filter((el) => el.selected);
      return obj[0];
    },
    selectedDate() {
      try {
        return {
          date:
            this.date.getFullYear() +
            "-" +
            (Number(this.date.getMonth()) + 1) +
            "-" +
            this.date.getDate(),
          croppedDate:
            this.date.toDateString().substring(0, 3) +
            this.date.toDateString().substring(7, 10) +
            this.date.toDateString().substring(3, 7),
        };
      } catch (error) {
        return null;
      }
    },
    selectedTime() {
      const obj = this.hours.filter((el) => el.selected);
      return obj[0];
    },
    profile() {
      return this.$store.state.profile.data;
    },
    currentBooking() {
      const booking = this.$store.state.profile.data.bookings.filter((el) =>
        el.visitId === this.$route.params.visitId
          ? this.$route.params.visitId
          : this.$route.query.visitId
      );
      return booking[0];
    },
    bookedDate() {
      const month =
        this.currentBooking.arrival[1] < 10
          ? "0" + this.currentBooking.arrival[1]
          : this.currentBooking.arrival[1];
      const date =
        this.currentBooking.arrival[0] +
        "-" +
        month +
        "-" +
        this.currentBooking.arrival[2];
      return new Date(date);
    },
    bookedTime() {
      let hour =
        this.currentBooking.arrival[3] < 10
          ? "0" + this.currentBooking.arrival[3]
          : this.currentBooking.arrival[3];
      let minute =
        this.currentBooking.arrival[4] < 10
          ? "0" + this.currentBooking.arrival[4]
          : this.currentBooking.arrival[4];
      return "" + hour + ":" + minute;
    },
    breakfast() {
      return this.hours.filter(
        (time) =>
          time.time > "07:00" &&
          time.time < "11:30" &&
          this.allowedTimes.findIndex((o) => {
            return o === time.time;
          }) > -1
      );
    },
    lunch() {
      return this.hours.filter(
        (time) =>
          time.time > "11:00" &&
          time.time < "17:00" &&
          this.allowedTimes.findIndex((o) => {
            return o === time.time;
          }) > -1
      );
    },
    dinner() {
      return this.hours.filter(
        (time) =>
          time.time > "16:30" &&
          time.time < "23:00" &&
          this.allowedTimes.findIndex((o) => {
            return o === time.time;
          }) > -1
      );
    },
    bookedWeekday() {
      const weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";
      if (this.date) {
        return weekday[this.date.getDay()];
      } else {
        return null;
      }
    },
    availableDates() {
      let availableDates = [];
      if (this.currentBooking.promotionId) {
        this.promotion?.dates?.forEach((date) => {
          availableDates.push(this.getDate(date));
        });
      }
      return availableDates;
    },
    disabledDates() {
      const weekdays = new Array(7);
      weekdays[1] = "Sunday";
      weekdays[2] = "Monday";
      weekdays[3] = "Tuesday";
      weekdays[4] = "Wednesday";
      weekdays[5] = "Thursday";
      weekdays[6] = "Friday";
      weekdays[7] = "Saturday";
      let disabledWeekdays = [];
      if (!this.availableDates && this.recommendation?.jsonOperatingHours) {
        let operatingHours = JSON.parse(this.recommendation.jsonOperatingHours);
        for (let i = 0; i < weekdays.length; i++) {
          let weekday = weekdays[i];
          if (!operatingHours[weekday]) {
            disabledWeekdays.push(i);
          }
        }
        return { weekdays: disabledWeekdays };
      }
      return null;
    },
    localTime() {
      return `${this.localDate.getHours()}:${this.localDate.getMinutes()}`;
    },
    localDate() {
      if (!this.recommendation.city) return new Date();
      Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + h * 60 * 60 * 1000);
        return this;
      };
      let userOffset = Number(new Date().getTimezoneOffset()) / -60;
      let recommendationOffset = api.getTimeOffset(this.recommendation.city);
      let difference = Number(recommendationOffset) - userOffset;
      return new Date().addHours(difference);
    },
    allowedTimes() {
      if (!this.bookedWeekday) {
        return [];
      }
      let allowedTimes = [];
      if (!this.currentBooking.promotionId) {
        let operatingHours = JSON.parse(this.recommendation.jsonOperatingHours);
        operatingHours = operatingHours[this.bookedWeekday];
        for (let i = 0; i < operatingHours.length; i++) {
          let from = operatingHours[i].from;
          let to = operatingHours[i].to;
          let hour, minute;
          if (
            from.split(" ")[1] === "pm" &&
            from.split(" ")[0].split(":")[0] !== "12"
          ) {
            hour = Number(from.split(" ")[0].split(":")[0]) + 12;
            from = hour + ":" + from.split(" ")[0].split(":")[1];
          } else {
            from = from.split(" ")[0];
          }
          if (
            to.split(" ")[1] === "pm" &&
            to.split(" ")[0].split(":")[0] !== "12"
          ) {
            hour = Number(to.split(" ")[0].split(":")[0]) + 12;
            to = hour + ":" + to.split(" ")[0].split(":")[1];
          } else {
            to = to.split(" ")[0];
          }
          if (to < from) {
            hour = Number(to.split(":")[0]) + 24;
            to = hour + ":" + to.split(":")[1];
          }
          let counter = 0;
          for (let j = from; j <= to && counter < 100; counter++) {
            hour = Number(j.split(":")[0]);
            minute = Number(j.split(":")[1]);
            let hourString = (hour < 10 ? "0" : "") + String(hour);
            let minuteString = (minute < 10 ? "0" : "") + String(minute);
            allowedTimes.push(hourString + ":" + minuteString);
            minute += 30;
            if (minute >= 60) {
              minute -= 60;
              hour += 1;
            }
            hourString = (hour < 10 ? "0" : "") + hour;
            minuteString = (minute < 10 ? "0" : "") + minute;
            j = hourString + ":" + minuteString;
          }
        }
      } else {
        let ticket = find(this.$store.state.reserveout.promotions, (o) => {
          return o.id === this.currentBooking.promotionId;
        });
        // Add an end time if there is none.
        if (!ticket.endTime) {
          ticket.endTime = [0, 0, 0, 0];
        }
        let start = Number(ticket.startTime[0]);
        // Adding 30 minute increments.
        let startMinute = Number(ticket.startTime[1]);
        let end =
          Number(ticket.endTime[0]) +
          (ticket.endTime[0] < ticket.startTime[0] ? 24 : 0);
        let endMinute = Number(ticket.endTime[1]);

        // Creating the list of possible times.
        for (let i = start; i <= end; i++) {
          // Just in case the time window passes midnight.
          let hour = i > 24 ? i - 24 : i;
          // This adds an item for every hour. It copies the minute from the startTime.
          allowedTimes.push(
            (hour < 10 ? "0" : "") +
              hour +
              ":" +
              (startMinute < 10 ? "0" : "") +
              startMinute
          );
          // This adds a 30 minute increments.
          if (i <= end) {
            // Calculating the minute for this item.
            let nextMinute =
              startMinute + 30 >= 60 ? startMinute + 30 - 60 : startMinute + 30;
            // Calculating the hour for this item.
            hour += startMinute + 30 >= 60 ? 1 : 0;
            // Making sure the added item is not after the end time.
            if (hour > end || (hour == end && nextMinute > endMinute)) {
              return allowedTimes;
            }

            // Finally, adding it to the times-list.
            allowedTimes.push(
              (hour < 10 ? "0" : "") +
                hour +
                ":" +
                (nextMinute < 10 ? "0" : "") +
                nextMinute
            );
          }
        }
      }
      if (
        allowedTimes &&
        this.date.toDateString() === new Date().toDateString()
      ) {
        let todaysAllowedTimes = [];
        allowedTimes.forEach((time) => {
          if (time > this.localTime) {
            todaysAllowedTimes.push(time);
          }
        });
        return todaysAllowedTimes;
      }
      return allowedTimes;
    },
    recommendation() {
      let recommendation = this.currentBooking.restaurant;
      return recommendation;
    },
  },
  methods: {
    select(item) {
      if (this.peopleActive) {
        this.guests.forEach((el) => {
          el.selected = false;
        });
      } else if (this.timeActive) {
        this.hours.forEach((el) => {
          el.selected = false;
        });
        this.progress = 100;
        this.peopleActive = true;
        this.calendarActive = false;
        this.timeActive = false;
      }
      item.selected = !item.selected;
    },
    getTimes() {
      for (var i = 0; i < 24; i++) {
        for (var j = 0; j < 2; j++) {
          var time = { time: i + ":" + this.halfHours[j], selected: false };
          if (i < 10) {
            time = { time: "0" + time.time, selected: false };
          }
          time.selected = time.time === this.bookedTime;
          this.hours.push(time);
        }
      }
    },
    getDate(dateArray) {
      let date = new Date(
        Number(dateArray[0]),
        Number(dateArray[1]) - 1,
        Number(dateArray[2])
      );
      return date;
    },
    modifyReservation() {
      try {
        if (!this.currentBooking.promotionId) {
          this.$store.dispatch("reserveout/checkAvailability", {
            countryId: this.$store.state.reserveout.countryId,
            placeId: this.$store.state.reserveout.placeId,
            covers: this.selectedGuests.number,
            date: this.selectedDate.date,
            time: this.selectedTime.time,
            visitId: this.currentBooking.visitId,
            header: this.$store.state.reserveout.header,
          });
        } else {
          this.$store.commit("tickets/SET_TICKET_OPTIONS", {
            tickettype: "reserve-out",
            restaurantId: this.$store.state.reserveout.placeId,
            numberoftickets: this.selectedGuests.number,
            date: this.selectedDate.date,
            time: this.selectedTime.time,
            ticketId: this.currentBooking.promotionId,
            placeName: this.$store.state.reserveout.header,
            countryId: this.$store.state.reserveout.countryId,
            visitId: this.currentBooking.visitId,
          });
          this.$router.safePush({
            name: "ticketoptions",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    // preselect the booked guests, time and date
    getBookedGuests() {
      const foundIndex = this.guests.findIndex(
        (x) => x.number == this.currentBooking.covers
      );
      this.guests[foundIndex].selected = true;
      // center preselected guest
      this.guestModel = foundIndex;
    },
    async getPromotion() {
      await this.$store.dispatch("reserveout/getPromotions", {
        restaurantId: this.currentBooking.restaurant.id,
      });
      this.promotion = find(this.$store.state.reserveout.promotions, (o) => {
        return o.id === this.currentBooking.promotionId;
      });
    },
  },
  mounted() {
    this.getPromotion();
    this.getTimes();
    this.getBookedGuests();
    this.date = this.bookedDate;
    this.$store.dispatch("recommendations/getDetails", {
      entryId: this.currentBooking.recommendationId,
    });
    this.$store.dispatch("reserveout/getPromotions", {
      restaurantId: this.currentBooking.restaurant.id,
    });
  },
  watch: {
    date(val) {
      if (val && this.mounted) {
        this.progress = 70;
        this.calendarActive = false;
        this.timeActive = true;
        this.peopleActive = false;
      } else {
        this.mounted = true;
      }
    },
  },
};
</script>
